<template>
  <a-modal
    :visible="show"
    @cancel="handleCancel"
    :width="width"
    :maskClosable="false"
    :centered="true"
  >
    <!-- TITLE -->
    <template slot="title">
      <font-awesome-icon
        class="anticon text-primary"
        :icon="['fas', 'list-check']"
      />
      {{ title }}
    </template>

    <!-- CONTENT -->
    <a-row
      v-if="showSearch"
      type="flex"
      justify="start"
      :gutter="16"
      :style="{
        alignItems: 'flex-end',
        marginTop: '0px',
        marginBottom: '10px',
      }"
    >
      <a-col :lg="12" :md="12" :xs="24">
        <a-input-search
          :placeholder="labels.placeholderSearch"
          style="width: 100%"
          @search="handleSearch"
          allowClear
        />
      </a-col>
    </a-row>
    <StandardTable
      ref="standardTable"
      :fetchService="fetchService"
      :columns="columns"
      :data="dataSource"
      :disabledDefaultFetchRequest="disabledDefaultFetchRequest"
      :locale="locale"
      :handleShowTotal="handleShowTotal"
      :scroll="{ x: true }"
      :size="'small'"
      :rowKey="rowKey"
      :selection="selection"
      :rowSelection="rowSelection"
      :onSelectRow="onSelectRow"
      :onSelectAll="onSelectAll"
      :selectedRowsKey="selectedRowsKey"
      :defaultFilters="defaultFilters"
    />
    <!-- FOOTER -->
    <template slot="footer">
      <a-button type="danger" ghost @click="handleCancel">
        <span>{{ labels.cancelButton }}</span>
      </a-button>
      <a-button type="primary" @click="handleOk" :disabled="!selected">
        <span>{{ labels.okButton }}</span>
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import StandardTable from "../table/StandardTable.vue";
import labels from "@/utils/labels";
export default {
  components: { StandardTable },
  name: "TableModal",
  data() {
    return {
      labels: labels.tableModal,
      selectedRows: [],
      selectedRowsKey: [],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Modal",
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "80%",
    },
    fetchService: {
      type: Function,
      default: null,
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dataSource: {
      type: Array,
      default: function () {
        return [];
      },
    },
    disabledDefaultFetchRequest: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
      default: "id",
    },
    size: {
      type: String,
      default: "default",
    },
    selection: {
      type: Boolean,
      default: false,
    },
    rowSelection: {
      type: Object,
      default: function () {
        return {};
      },
    },
    defaultSelected: {
      type: Array,
      default: function () {
        return [];
      },
    },
    defaultFilters: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleOk() {
      if (this.selectedRows.length) {
        this.$emit(
          "onSelect",
          this.rowSelection.type === "checkbox"
            ? this.selectedRows
            : this.selectedRows[0]
        );
        this.cleanSelection();
      }
    },
    handleCancel() {
      this.$emit("onCancel");
      this.cleanSelection();
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    onSelectRow(selectedRows) {
      this.selectedRows = selectedRows;
    },
    onSelectAll(selectedRows) {
      this.selectedRows = selectedRows;
    },
    cleanSelection() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
  },
  computed: {
    locale() {
      return {
        emptyText: this.labels.emptyText,
      };
    },
    okText() {
      return this.rowSelection?.type === "checkbox"
        ? `${this.labels.okButton} (${this.selectedRows.length})`
        : this.labels.okButton;
    },
    selected() {
      return (
        this.selectedRows.filter((row) => row && row[this.rowKey]).length > 0
      );
    },
  },
  watch: {
    selectedRows: {
      handler(val) {
        this.selectedRowsKey = val.map((item) => item[this.rowKey]);
      },
      deep: true,
    },
    defaultSelected: {
      handler(val) {
        this.selectedRows = val;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.text-primary {
  color: color(primary);
}
</style>
